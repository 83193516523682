import moment from 'moment';
import React from 'react'
import localization from 'moment/locale/id';

moment.updateLocale('id', localization);

const DonationList = props => {
    moment.locale('id');
    const { data, sum, date } = props;
    console.log('data yg masuk');
    console.log(data);
    const dataPerPage = props.dataPerPage || 5;

    const [currentPage, setCurrentPage] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(1);
    // const [dataSize, setDataSize] = React.useState(5);

    const toRupiah = (number) => {
        return new Intl.NumberFormat(
            'id-ID',
            {
                style: 'currency',
                currency: 'IDR',
                maximumFractionDigits: 0
            }
        ).format(number)
    }
    const getTotalPages = () => {
        if (data && data.length > 0) setTotalPages(Math.ceil(data.length / dataPerPage))
    }
    const previousPage = () => setCurrentPage(currentPage - 1)
    const nextPage = () => setCurrentPage(currentPage + 1)

    const PreviousButton = () => {
        if (currentPage > 0) return <button class="btn btn-sm" onClick={previousPage}>&lt;</button>
        else return <button class="btn btn-sm" disabled>&lt;</button>
    }
    const NextButton = () => {
        if (currentPage + 1 < totalPages) return <button class="btn btn-sm" onClick={nextPage}>&gt;</button>
        else return <button class="btn btn-sm" disabled>&gt;</button>
    }
    React.useEffect(() => {
        console.log('useEffect is running')
        getTotalPages()
    }, [data])
    return (
        <React.Fragment>
            <div className="my-4">
                <div className="row">
                    <div className='col-5'>
                        <p>Total Donasi</p>
                        <h3>{toRupiah(sum)}</h3>
                    </div>
                    <div className='d-flex col-7 justify-content-end align-items-center'>
                        <PreviousButton />
                        {/* <span class="h4"> */}
                        &nbsp; {currentPage + 1} / {totalPages} &nbsp; 
                        {/* </span> */}
                        <NextButton />
                    </div>

                </div>
            </div>
            <div>
                {data && data.length > 0 ? data.slice(currentPage * dataPerPage, currentPage * dataPerPage + dataPerPage).map((donation, idx) => (
                    <div class="card mb-3" key={idx}>
                        <div class="card-body">
                            <div className="row">
                                <div class="col-7">
                                    <h5 class="card-title">{donation.donorName}</h5>
                                    <p class="card-text">
                                    <i class="fa-regular fa-calendar" /> &nbsp;
                                        {moment(donation.date).locale('id').format('LL')}
                                    </p>
                                </div>
                                <div class="col-5 d-flex justify-content-end">
                                    <div className="d-flex align-items-end">
                                        <h5 className='text-primary'>{toRupiah(donation.amount)}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <p class="text-center">Belum ada data donasi sepanjang {moment(date).format('MMMM YYYY')}.</p>}
                
            </div>
        </React.Fragment>
    )
}

export default DonationList;
