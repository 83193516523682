import React from 'react'
import { Line } from 'react-chartjs-2'

const AreaChart = props => {
    const {
        data,
        options
    } = props
    
    return (
        <Line
            data={data}
            options={options}
        />
    )
}

export default AreaChart