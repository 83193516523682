import logo from './logo.svg';
import './App.css';
import Routes from "./routers";

function App() {
  return (
    <div className="main">
      <Routes />
    </div>
  );
}

export default App;
