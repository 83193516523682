import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as RoutesR,
} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import FeatureMobile from "../pages/FeatureMobile";
import FeatureWebsite from "../pages/FeatureWebsite";
import RamadanTrack from "../pages/RamadanTrack";
import TermCondition from "../pages/TermCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Donate from "../pages/Donate";
// importing all the themes

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <RoutesR>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/mobile-feature" element={<FeatureMobile />} />
            <Route path="/website-feature" element={<FeatureWebsite />} />
            <Route path="/ramadan-track" element={<RamadanTrack />} />
            <Route path="/terms-condition" element={<TermCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/donate" element={<Donate />} />
          </RoutesR>
        </Router>
      </React.Fragment>
    );
  }
}
