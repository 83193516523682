import * as React from 'react';
import { Link } from 'react-router-dom'

const Navbar = () => {

  return (
    <React.Fragment>
        <footer class="section footer-area">
            <div class="footer-top ptb_100">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-6">
                            <div class="footer-items">
                                <img src="assets/img/footer-logo.png" className='mb-3' />
                                <p class="text-white mb-2">
                                    Amazing Riyadhoh, <br /> sahabat untuk meningkatkan ketaqwaanmu
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3">
                            <div class="footer-items">
                                <h3 class="text-white text-uppercase mb-2">Link</h3>
                                <ul>
                                    <li class="py-2">
                                        <Link to={`/`} className="text-white">
                                            Beranda
                                        </Link>
                                    </li>
                                    <li class="py-2">
                                        <Link to={`/mobile-feature`} className="text-white">
                                            Fitur Mobile
                                        </Link>
                                    </li>
                                    <li class="py-2">
                                        <Link to={`/website-feature`} className="text-white">
                                            Fitur Website
                                        </Link>
                                    </li>
                                    <li class="py-2">
                                        <Link to={`/ramadan-track`} className="text-white">
                                            Ramadan Track
                                        </Link>
                                    </li>
                                    <li class="py-2">
                                        <Link to={`/about-us`} className="text-white">
                                            Tentang Kami
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3">
                            <div class="footer-items">
                                <h3 class="text-white text-uppercase mb-2">Kontak</h3>
                                <ul>
                                    <li class="py-2"> 
                                        <p class="text-white">
                                            <i class="fas fa-envelope-open-text"></i>: 
                                            Salam@AmazingRiyadhoh.id
                                        </p>
                                    </li>
                                    <li class="py-2">
                                        {/* <p class="text-white">
                                            <i class="fa-solid fa-location-dot"></i>
                                        </p> */}
                                        <p class="text-white">
                                            Sentra Timur Residence, COMMERCIAL PARK 2, 
                                            Jl.Cemp. No.15, RW.6, Pulo Gebang, Cakung 
                                            Kota Jakarta Timur, Jakarta 13950
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="copyright-area d-flex flex-wrap justify-content-center text-center py-4">
                                <div>Copyright © 2022 All Rights Reserved - Amazing Riyadhoh</div>
                                {/* <div class="copyright-right">Made with <i class="fas fa-heart"></i> By <a href="#">Themeland</a></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </React.Fragment>
  );
};
export default Navbar;
