import React from 'react';
import { 
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';

const CURRENT_URL = window.location.href;
const ShareDialog = props => {
    // const { handleCopy } = props;
    const {copied, setCopied} = props;

    const handleCopyText = async () => {
        try {
            await navigator.clipboard.writeText(CURRENT_URL)
            setCopied(true)
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <React.Fragment>
        
            <div id="share-dialog" class="modal fade" tabindex="-1" role="dialog"   >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Bagikan ke:</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <WhatsappShareButton url={CURRENT_URL} title='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <WhatsappIcon round size="56px"/>
                                    </WhatsappShareButton>
                                    <FacebookShareButton url={CURRENT_URL} title='Facebook' quote='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <FacebookIcon round size="56px" />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={CURRENT_URL} title='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <TwitterIcon round size="56px" />
                                    </TwitterShareButton>
                                    <TelegramShareButton url={CURRENT_URL} title='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <TelegramIcon round size="56px" />
                                    </TelegramShareButton>
                                    <LinkedinShareButton url={CURRENT_URL} title='Amazing Riyadhoh' summary='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <LinkedinIcon round size="56px" />
                                    </LinkedinShareButton>
                                    <LineShareButton url={CURRENT_URL} title='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <LineIcon round size="56px" />
                                    </LineShareButton>
                                    <EmailShareButton url={CURRENT_URL} title='E-mail' subject='Amazing Riyadhoh' body='Yuk ikut serta dalam pengembangan aplikasi Amazing Riyadhoh!'>
                                        <EmailIcon round size="56px" />
                                    </EmailShareButton>
                                    <br />
                                </div>
                                
                                <div className="row mt-4 justify-content-center">
                                    <div className="input-group">
                                        <input className='form-control' type="text" name="current-url" id="current-url" value={CURRENT_URL} onClick={handleCopyText}/>
                                        <div className="input-group-text copy-text" onClick={handleCopyText}>
                                            <button className='btn btn-text text-white'>
                                                <i class="fa-regular fa-copy fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {copied && <div class="alert alert-success mt-4 text-center" role="alert">
                                    Berhasil disalin!
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ShareDialog;
