import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'

const About = () => {
    return (
        <React.Fragment>
            <Navbar />
            <section id="home" class="section welcome-area bg-overlay d-flex align-items-center">
            </section>
            <section id="review" className="section review-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="section-heading text-center">
                                <p style={{ fontSize: "32px", letterSpacing: "0.15px", lineHeight: "40px" }} className="mt-4">
                                    Segera Hadir Untuk Menyempurnakan Monitoring
                                    <div style={{ color: "#2C75C0" }}>
                                    Evaluasi Ibadah Anda
                                    </div>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className='section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12" style={{ verticalAlign: "bottom" }}>
                            <div className="section-heading text-center">
                                <img src="assets/img/coming-soon.svg" style={{ verticalAlign: "bottom" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default About