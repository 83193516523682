import React from "react";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";

const TermCondition = () => {
  return (
    <>
      <Navbar />
      <section id="termcondition" className="termcondition-area">
        <br />
        <br />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-12">
              <div className="welcome-intro">
                <h2 className="text-black text-center text-md-left">
                  Terms Condition
                </h2>
                <br />
                <p>
                  Dengan mengunduh atau menggunakan aplikasi, persyaratan ini
                  akan berlaku secara otomatis untuk Anda – karena itu Anda
                  harus memastikan bahwa Anda membacanya dengan cermat sebelum
                  menggunakan aplikasi. Anda tidak diizinkan untuk menyalin atau
                  memodifikasi aplikasi, bagian mana pun dari aplikasi, atau
                  merek dagang kami dengan cara apa pun. Anda tidak boleh
                  mencoba mengekstrak kode sumber aplikasi, dan Anda juga tidak
                  boleh mencoba menerjemahkan aplikasi ke bahasa lain atau
                  membuat versi turunan. Aplikasi itu sendiri, dan semua merek
                  dagang, hak cipta, hak basis data, dan hak kekayaan
                  intelektual lainnya yang terkait dengannya adalah milik PT.
                  EOA Technology International.
                  <br />
                  <br />
                  Aplikasi Amazing Riyadhoh menyimpan dan memproses data pribadi
                  yang telah Anda berikan kepada kami, untuk menyediakan Layanan
                  kami. Anda bertanggung jawab untuk menjaga keamanan ponsel dan
                  akses ke aplikasi.
                  <br />
                  <br />
                  Perlu Anda ketahui bahwa ada beberapa hal yang PT. EOA
                  Technology International tidak bertanggung jawab. Fungsi
                  aplikasi tertentu akan mengharuskan aplikasi memiliki koneksi
                  internet aktif. Koneksi dapat berupa Wi-Fi atau disediakan
                  oleh penyedia jaringan seluler Anda, tetapi PT. EOA Technology
                  International tidak bertanggung jawab atas aplikasi yang tidak
                  berfungsi penuh jika Anda tidak memiliki akses ke Wi-Fi, dan
                  Anda tidak memiliki sisa kuota data.
                  <br />
                  <br />
                  Sejalan dengan itu, PT. EOA Technology International tidak
                  selalu bertanggung jawab atas cara Anda menggunakan aplikasi,
                  yaitu Anda perlu memastikan bahwa perangkat Anda tetap terisi
                  daya – jika baterai habis dan Anda tidak dapat menyalakannya
                  untuk memanfaatkan Layanan, PT. EOA Technology International
                  tidak bisa menerima tanggung jawab.
                  <br />
                  <br />
                  Sehubungan dengan Tanggung jawab PT. EOA Technology
                  International atas penggunaan aplikasi oleh Anda, ketika Anda
                  menggunakan aplikasi, penting untuk diingat bahwa meskipun
                  kami berusaha untuk memastikan bahwa itu diperbarui dan benar
                  setiap saat, kami mengandalkan pihak ketiga untuk memberikan
                  informasi kepada kami sehingga kami dapat menyediakannya untuk
                  Anda. PT. EOA Technology International tidak bertanggung jawab
                  atas kerugian apa pun, langsung atau tidak langsung, yang Anda
                  alami sebagai akibat dari mengandalkan sepenuhnya fungsi
                  aplikasi ini.
                  <br />
                  <br />
                  Pada titik tertentu, kami mungkin ingin memperbarui aplikasi.
                  Aplikasi saat ini tersedia pada Android & iOS – persyaratan
                  untuk kedua sistem (dan untuk sistem tambahan apa pun yang
                  kami putuskan untuk memperpanjang ketersediaan aplikasi) dapat
                  berubah, dan Anda harus mengunduh pembaruan jika ingin tetap
                  menggunakan aplikasi. PT. EOA Technology International tidak
                  menjanjikan akan selalu memperbarui aplikasi agar relevan
                  dengan Anda dan/atau bekerja dengan versi Android & iOS yang
                  telah Anda instal di perangkat Anda. Namun, Anda berjanji
                  untuk selalu menerima pembaruan aplikasi saat ditawarkan
                  kepada Anda, Kami mungkin juga ingin berhenti menyediakan
                  aplikasi, dan dapat menghentikan penggunaannya kapan saja
                  tanpa memberikan pemberitahuan penghentian kepada Anda.
                  Kecuali jika kami memberi tahu Anda sebaliknya, setelah
                  penghentian apa pun, (a) hak dan lisensi yang diberikan kepada
                  Anda dalam persyaratan ini akan berakhir; (b) Anda harus
                  berhenti menggunakan aplikasi, dan (jika perlu) menghapusnya
                  dari perangkat Anda.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
      <Footer />
    </>
  );
};

export default TermCondition;
