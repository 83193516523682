import * as React from 'react';
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'
import Odometer from 'react-odometerjs';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import moment from 'moment';

const ResponsiveAppBar = () => {

    const owlResponsive = {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        768: {
            items: 2
        },
        992: {
            items: 3
        }
    }

    // const date = new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(Date.now())
    const [ countUpVerse, setCountUpVerse ] = React.useState(0)
    const [ totalUsers, setTotalUsers ] = React.useState(0)
    // const [ startDate, setStartDate ] = React.useState(new Date())
    const [ endDate ] = React.useState(moment().format('YYYY-MM-DD'))

    async function getStatistic() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/ramadhan-track?startDate=2022-04-03&endDate=${endDate}`);
          setCountUpVerse(response.data.data.versesTotal)
          setTotalUsers(response.data.data.usersTotalSinceRelease)
        } catch (error) {
          console.error(error);
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            getStatistic()
          }, 1000);
          return () => clearInterval(interval);
    })

    return (
        <React.Fragment>
            <Navbar />
            <section id="home" className="section welcome-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="welcome-intro">
                                <h2 className="text-white text-center text-md-left">Tanamkan Kebaikan <br /> Sekarang Juga</h2>
                                <p className="text-white text-center text-md-left my-4">Download Aplikasi</p>
                                
                            </div>
                            <div className="welcome-intro" style={{ marginTop: "10px"}}>
                                <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                    <a href="https://play.google.com/store/apps/details?id=dev.eoatech.yaumi" target="_blank" rel="noreferrer" className="mr-3">
                                        <img src="./assets/img/website/icon/google_play.png" alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/id/app/amazing-riyadhoh/id1560939436?l=id" target="_blank" rel="noreferrer">
                                        <img src="./assets/img/website/icon/app_store.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 align-self-end">
                            <div className="welcome-thumb-wrapper pt-5 pt-md-5 mt-5">
                                <span className="welcome-thumb-1">
                                    <img className="d-block ml-auto float-right" src="assets/img/flyer-landing-page.png" alt="" />
                                </span>
                            </div>
                        </div>
                        {/* <img src="assets/img/hero1.png" alt="" /> */}
                    </div>
                </div>
            </section>
            <section className="section about-area">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/flyer-home.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2 className='text-center'>Amazing Riyadhoh</h2>
                                </div>
                                <p className="text-justify">
                                Aplikasi muslim dengan banyak fitur bermanfaat.
                                Membantu kamu melakukan evaluasi ibadah harian.  
                                </p> <br />
                                <p>Segera Download Aplikasi</p>
                                <div className="welcome-intro" style={{ marginTop: "10px"}}>
                                    <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                        <a href="https://play.google.com/store/apps/details?id=dev.eoatech.yaumi" target="_blank" rel="noreferrer" className="mr-3">
                                            <img src="./assets/img/website/icon/google_play.png" alt="" />
                                        </a>
                                        <a href="https://apps.apple.com/id/app/amazing-riyadhoh/id1560939436?l=id" target="_blank" rel="noreferrer">
                                            <img src="./assets/img/website/icon/app_store.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3 text-center">
                                    <h2>Akumulasi ayat Al-Qur’an yang telah dibaca</h2>
                                    <p>#BackToReadQuran #Ramadan1443H #3April-3Mei</p>
                                </div>
                                <p style={{ fontSize: "50px"}} className="text-center">
                                    <a href={`/ramadan-track#graphic`}>
                                        <Odometer value={countUpVerse} format="(.ddd),dd" />
                                    </a>
                                </p>
                                <p className="text-center">Click for detail</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/flyer-countdown.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            <div className="section-heading text-center">
                                <h2>
                                    Dukung Kami Selalu Membuat &nbsp;
                                    <div style={{ color: "#2C75C0", display: "inline" }}>
                                        Hal - Hal Baik 
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-12">
                            <div className="about-thumb text-center">
                                <img src="assets/img/maps-indo.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="text-center mt-4">
                                <p>
                                    Dukung dan doakan Amazing Riyadhoh agar aplikasi ini bisa <br /> digunakan kaum muslimin di seluruh dunia agar bisa merasakan manfaatnya. 
                                </p>
                            </div>
                            <div className="text-center mt-3">
                                <h4>
                                    Sejak 2021, User Amazing Riyadhoh
                                </h4>
                            </div>
                            <div className="text-center mt-3">
                                <h3 style={{ color: "#2C75C0", display: "inline" }}>
                                    {new Intl.NumberFormat('en-US').format(totalUsers)} User
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="review" className="section review-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            <div className="section-heading text-center">
                                <h2 className="">Harapan dan Doa</h2>
                                <p className=" d-none d-sm-block mt-4">Pengalaman dari para user tentang penggunaan Amazing Riyadhoh</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <OwlCarousel loop margin={10} responsive={owlResponsive} nav={true}>
                            <div className="item single-review p-5">
                                <div className="review-content">
                                    <div className="review-text">
                                        <p>Dengan adanya aplikasi ini semoga membantu peningkatan ketaqwaan kita terhadap Allah Swt dan semakin ikhlas dalam menjalankan setiap aktifitas ibadahnya.</p>
                                    </div>
                                    <div className="quot-icon">
                                        <img className="avatar-sm" src="assets/img/quote.png" alt="" />
                                    </div>
                                </div>
                                <div className="reviewer media mt-3">
                                    <div className="reviewer-thumb">
                                        <img className="avatar-lg radius-100" src="assets/img/avatar/user.png" alt="" />
                                    </div>
                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                        <h5 className="reviewer-name color-primary mb-2">Bustanul Arifin</h5>
                                        <h6 className="text-secondary fw-6">Karyawan Swasta</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="item single-review p-5">
                                <div className="review-content">
                                    <div className="review-text">
                                        <p>
                                            Aplikasi yang sangat membantu untuk menciptakan habbit spiritual dan mengevaluasi ibadah harian baik wajib dan sunnah
                                            <br />
                                            <br />
                                        </p>
                                    </div>
                                    <div className="quot-icon">
                                        <img className="avatar-sm" src="assets/img/quote.png" alt="" />
                                    </div>
                                </div>
                                <div className="reviewer media mt-3">
                                    <div className="reviewer-thumb">
                                        <img className="avatar-lg radius-100" src="assets/img/avatar/user.png" alt="" />
                                    </div>
                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                        <h5 className="reviewer-name color-primary mb-2">Mukti Dwi J</h5>
                                        <h6 className="text-secondary fw-6">System Analyst</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="item single-review p-5">
                                <div className="review-content">
                                    <div className="review-text">
                                        <p>Masyaallah sangat sangat membantu dalam meningkatkan ketakwaan kepada Allah. Dan aplikasi ini harus dipakai banyak umat Islam. </p>
                                    </div>
                                    <div className="quot-icon">
                                        <img className="avatar-sm" src="assets/img/quote.png" alt="" />
                                    </div>
                                </div>
                                <div className="reviewer media mt-3">
                                    <div className="reviewer-thumb">
                                        <img className="avatar-lg radius-100" src="assets/img/avatar/user.png" alt="" />
                                    </div>
                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                        <h5 className="reviewer-name color-primary mb-2">Rezha Rendy</h5>
                                        <h6 className="text-secondary fw-6">Trainer</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="item single-review p-5">
                                <div className="review-content">
                                    <div className="review-text">
                                        <p>Semoga menu dalam aplikasi ini bertambah, terutama untuk Al Quran yg ada penandanya agar tilawahnya bisa dilakukan secara teratur.</p>
                                    </div>
                                    <div className="quot-icon">
                                        <img className="avatar-sm" src="assets/img/quote.png" alt="" />
                                    </div>
                                </div>
                                <div className="reviewer media mt-3">
                                    <div className="reviewer-thumb">
                                        <img className="avatar-lg radius-100" src="assets/img/avatar/user.png" alt="" />
                                    </div>
                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                        <h5 className="reviewer-name color-primary mb-2">Pandoe Septa</h5>
                                        <h6 className="text-secondary fw-6">Karyawan Swasta</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="item single-review p-5">
                                <div className="review-content">
                                    <div className="review-text">
                                        <p>
                                            Aplikasi yang sangat bagus untuk tracking kegiatan ibadah harian, keep up the good work for app developer.
                                            <br />
                                            <br />
                                        </p>
                                    </div>
                                    <div className="quot-icon">
                                        <img className="avatar-sm" src="assets/img/quote.png" alt="" />
                                    </div>
                                </div>
                                <div className="reviewer media mt-3">
                                    <div className="reviewer-thumb">
                                        <img className="avatar-md radius-100" src="assets/img/avatar/user.png" alt="" />
                                    </div>
                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                        <h5 className="reviewer-name color-primary mb-2">Aditya Prayogo Kurniadi</h5>
                                        <h6 className="text-secondary fw-6">Karyawan Swasta</h6>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
};
export default ResponsiveAppBar;
