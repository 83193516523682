import * as React from 'react';
import { Link } from 'react-router-dom'

const Navbar = () => {

  return (
    <React.Fragment>
        <header id="header">
            <nav data-aos="zoom-out" data-aos-delay="800" className="navbar navbar-expand navbar-brand-sticky navbar-sticky">
                <div className="container header">
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-regular" src="assets/img/navbar-logo.png" alt="brand-logo" />
                        <img className="navbar-brand-sticky" src="assets/img/navbar-logo.png" alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto"></div>
                    <ul className="navbar-nav items">
                        <li className="nav-item">
                            <Link to={`/`} className="nav-link"> 
                                <p>
                                    Beranda
                                </p>  
                            </Link> 
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">
                                <p>Fitur <i class="fas fa-angle-down ml-1"></i></p>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <Link class="nav-link" to={`/mobile-feature`}>
                                        <p>
                                            Fitur Mobile
                                        </p>  
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to={`/website-feature`}>
                                        <p>
                                            Fitur Website
                                        </p>  
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to={`/ramadan-track#graphic`} className="nav-link"> 
                                <p>
                                    Ramadan Track
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`/about-us`} className="nav-link"> 
                                <p>
                                    Tentang Kami
                                </p>
                            </Link>
                        </li>
                    </ul>
                    {/* <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="fas fa-search"></i>
                            </a>
                        </li>
                        <li className="nav-item social">
                            <a href="#" className="nav-link"><i className="fab fa-facebook-f"></i></a>
                        </li>
                        <li className="nav-item social">
                            <a href="#" className="nav-link"><i className="fab fa-twitter"></i></a>
                        </li>
                    </ul> */}

                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <div id="menu" class="modal fade p-0">
            <div class="modal-dialog dialog-animated">
                <div class="modal-content h-100">
                    <div class="modal-header" data-dismiss="modal">
                        Menu <i class="far fa-times-circle icon-close"></i>
                    </div>
                    <div class="menu modal-body">
                        <div class="row w-100">
                            <div class="items p-0 col-12 text-center"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};
export default Navbar;
