import React, { useState } from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import moment from 'moment'
import axios from 'axios';
import { BarChart, LineChart, AreaChart } from '../components'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const RamadanTrack = () => {
    // const defStartDate = new Date("04-03-2022")
    // console.log(defStartDate)
    const [ startDate, setStartDate ] = useState("04/03/2022")
    const [ endDate, setEndDate ] = useState(moment().format("MM/DD/YYYY"))
    const [ dataStats, setDataStats ] = useState(null)
    const [ dataVerse, setDataVerse ] = useState(0)
    const [ dataUsers, setDataUsers ] = useState(0)

    const onChangeDate = (e) => {
        // console.log(e.target.value.split('-'))
        const date = e.target.value.split('-')
        setStartDate(date[0])
        setEndDate(date[1])
    }

    async function getStatistic() {
        // const initialStartDate = new Date(startDate)
        // const initialEndDate = new Date(endDate)
        const date1 = "2022-04-03"
        const date2 = "2022-05-03"
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/ramadhan-track?startDate=${startDate}&endDate=${endDate}`);
        //   console.log(response.data)
          setDataStats(response.data.data.stats)
          setDataVerse(response.data.data.versesTotal)
          setDataUsers(response.data.data.usersTotal)
        } catch (error) {
          console.error(error);
        }
    }

    let label = []
    let value = []
    let usersVal = []
    if(dataStats !== null){
        for (let index = 0; index < dataStats.length; index++) {
            const date = new Date(dataStats[index].date)
            label.push(moment(date).format('YYYY-MM-DD'))
            value.push(dataStats[index].verses)
            usersVal.push(dataStats[index].users)
        }
    }

    React.useEffect(() => {
        getStatistic()
    },[startDate, endDate])

    const data = {
        labels : label,
        defaultFontFamily: "Poppins",
        datasets: [
            {
                label: 'Akumulasi Ayat',
                data: value,
                backgroundColor: 'rgba(45, 118, 249, 1)',
            },
            // {
            //     label: 'User Berkontribusi',
            //     data: usersVal,
            //     backgroundColor: 'rgba(112, 187, 241, 1)',
            // }
        ]
    };

    const dataArea = {
        labels : label,
        defaultFontFamily: "Poppins",
        datasets: [
            {
                label: 'Akumulasi Ayat',
                data: value,
                backgroundColor: 'rgba(45, 118, 249, 1)',
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor : "#ff6c23",
                borderWidth: 2,
                pointColor : "#fff",
                pointStrokeColor : "#ff6c23",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "#ff6c23",
                fill: 'origin'
            },
            {
                label: 'User Berkontribusi',
                data: usersVal,
                backgroundColor: 'rgba(112, 187, 241, 1)',
            }
        ]
    };

    const options = {
        responsive: true,
        scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                },
              },
            ],
            xAxes: [
              {
                // Change here
                barPercentage: 0.5,
              },
            ],
        },
        plugins: {
          legend: {
            position: 'top',
          }
        },
    };

    const [ statsView, setStatsView ] = useState('bar')
    const handleChangeViewStats = event => {
        setStatsView(event)
    }

    return (
        <React.Fragment>
            <Navbar />
            <section id="graphic" className="section ptb_100 mt-5 mt-sm-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-12">
                            <div className="welcome-intro justify-content-center">
                                <h2 style={{ fontWeight: "600" }} className="text-center">Akumulasi Membaca Ayat Al-Qur’an
                                Selama Ramadan 1443 H</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="review" className="section review-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-4 col-lg-4">
                            <label htmlFor="">Pilih Tanggal</label>
                            <DateRangePicker
                                initialSettings={{ startDate: startDate, endDate: endDate }}
                                onApply={onChangeDate}
                            >
                                <input type="text" className="form-control" />
                            </DateRangePicker>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <label htmlFor="">Akumulasi ayat Al-Qur’an yang telah dibaca</label>
                            <input type="text" className='form-control' disabled value={dataVerse} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <label htmlFor="">Seluruh User Yang Membaca</label>
                            <input type="text" className='form-control' disabled value={dataUsers} />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section mt-5 mb-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <button className='btn btn-primary mr-3' onClick={() => handleChangeViewStats('bar')}>Bar Chart</button>
                            <button className='btn btn-primary mr-3' onClick={() => handleChangeViewStats('line')}>Line Chart</button>
                            {/* <button className='btn btn-primary' onClick={() => handleChangeViewStats('area')}>Area Chart</button> */}
                        </div>
                    </div>
                </div>
            </section>
            {statsView === 'bar' ? (
                <section className='section'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-12">
                                <BarChart
                                    data={data}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            ):(
                <>
                {statsView === 'line' ? (
                    <section className='section'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <LineChart
                                        data={data}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                ):(
                    <section className='section'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <AreaChart
                                        data={dataArea}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                </>
            )}
            <section className="section about-area mb-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/flyer-home.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2 className='text-center'>Amazing Riyadhoh</h2>
                                </div>
                                <p className="text-justify">
                                Aplikasi muslim dengan banyak fitur bermanfaat.
                                Membantu kamu melakukan evaluasi ibadah harian.  
                                </p> <br />
                                <p>Segera Download Aplikasi</p>
                                <div className="welcome-intro" style={{ marginTop: "10px"}}>
                                    <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                        <a href="https://play.google.com/store/apps/details?id=dev.eoatech.yaumi" target="_blank" className="mr-3">
                                            <img src="./assets/img/website/icon/google_play.png" alt="" />
                                        </a>
                                        <a href="https://apps.apple.com/id/app/amazing-riyadhoh/id1560939436?l=id" target="_blank">
                                            <img src="./assets/img/website/icon/app_store.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default RamadanTrack