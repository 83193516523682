import React from "react";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section id="privacypolicy" className="privacypolicy-area">
        <br />
        <br />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-12">
              <div className="welcome-intro">
                <h2 className="text-black text-center text-md-left">
                  Kebijakan Privasi
                </h2>
                <br />
                <p>
                  Adanya Kebijakan Privasi ini adalah komitmen nyata dari PT.
                  EOA Technology International. untuk menghargai dan melindungi
                  setiap data atau informasi pribadi pengguna aplikasi “Amazing
                  Riyadhoh”. Dengan mengklik “Daftar” (Register), pengguna
                  menyatakan bahwa setiap Data Pribadi Pengguna merupakan data
                  yang benar dan sah, pengguna mengakui bahwa ia telah
                  diberitahukan dan memahami ketentuan Kebijakan Privasi ini
                  serta Pengguna memberikan persetujuan kepada “Amazing
                  Riyadhoh” untuk memperoleh, mengumpulkan, mengolah,
                  menganalisis, menampilkan, mengirimkan, membuka, menyimpan,
                  mengubah, menghapus, mengelola, dan/atau mempergunakan data
                  tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan
                  Privasi.
                  <br />
                  <br />
                  <ul>
                    <li class="py-2">Kebijakan Privasi meliputi :</li>
                    <li class="py-2">1. Perolehan Data Pribadi</li>
                    <li class="py-2">2. Penggunaan Data</li>
                    <li class="py-2">
                      3. Pengaduan Terkait Perlindungan Data Pengguna
                    </li>
                    <li class="py-2">
                      4. Penyimpanan dan Penghapusan Informasi
                    </li>
                    <li class="py-2">5. Pembaruan Kebijakan Privasi</li>
                  </ul>
                  <br />
                  <ul>
                    <li class="py-2">
                      1. Perolehan Data Pribadi
                      <br /> “Amazing Riyadhoh” mengumpulkan Data Pribadi
                      Pengguna dengan tujuan untuk mengelola dan memperlancar
                      proses penggunaan aplikasi pencatatan amal ibadah. Data
                      yang diserahkan secara mandiri oleh pengguna, termasuk
                      namun tidak terbatas pada data yang diserahkan pada saat
                      Pengguna : <br />
                      1. Membuat akun “Amazing Riyadhoh”, termasuk diantaranya
                      nama pengguna (username), email, jenis kelamin, alamat
                      nomor telepon, password, foto, komunitas, dan informasi
                      lainnya yang dapat mengidentifikasi pengguna. <br />
                      2. Menghubungi “Amazing Riyadhoh”, termasuk melalui
                      layanan pelanggan (customer service). <br />
                      3. Mengisi data-data riyadhoh pada saat pengguna melakukan
                      aktivitas pencatatan amal melalui aplikasi.
                    </li>
                    <li class="py-2">
                      2. Penggunaan Data
                      <br /> “Amazing Riyadhoh” dapat menggunakan data pribadi
                      yang diperoleh dan dikumpulkan dari pengguna sebagaimana
                      disebutkan di dalam bagian “Perolehan Data Pribadi” untuk
                      hal-hal sebagai berikut : <br />
                      1. Memproses segala bentuk aktivitas transaksi yang
                      dilakukan oleh pengguna melalui aplikasi “Amazing
                      Riyadhoh”. <br />
                      2. Membantu pengguna pada saat berkomunikasi dengan
                      customer service “Amazing Riyadhoh” seperti: Memeriksa dan
                      mengatasi permasalahan pengguna. Menghubungi pengguna
                      melalui email, telepon atau komunikasi lainnya.
                      Menginformasikan kepada pengguna terkait hewan kurban dan
                      promosi. Memperbaiki layanan pelanggan “Amazing Riyadhoh”.{" "}
                      <br />
                      3. Melakukan monitoring jika terdapat tindakan-tindakan
                      yang mencurigakan <br />
                      4. Mengungkapkan Data Pribadi pengguna untuk tujuan hukum
                      atau persyaratan dan kewajiban peraturan
                      perundang-undangan.
                    </li>
                    <li class="py-2">
                      3. Pengaduan Terkait Perlindungan Data
                      <br /> Pengguna Jika pengguna khawatir tentang data
                      pribadi pengguna terkait privasinya, pengguna dapat
                      menghubungi customer service “Amazing Riyadhoh” dari
                      aplikasi “Amazing Riyadhoh” di menu hubungi kami, yang
                      ditandai dengan icon
                    </li>
                    <li class="py-2">
                      4. Penyimpanan dan Penghapusan Informasi
                      <br /> “Amazing Riyadhoh” akan menyimpan informasi selama
                      akun pengguna aktif dan tidak menutup kemungkinan
                      penghapusan akun.
                    </li>
                    <li class="py-2">
                      5. Pembaruan Kebijakan Privasi
                      <br /> Kebijakan Privasi dapat berubah sewaktu-waktu.
                      “Amazing Riyadhoh” menyarankan agar pengguna membaca dan
                      memeriksa halaman kebijakan privasi ini agar mengetahui
                      perubahan apapun.
                    </li>
                  </ul>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
