import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'

const FeatureMobile = () => {
    return (
        <React.Fragment>
            <Navbar />
            <section id="home" className="section welcome-area bg-overlay d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 d-none d-sm-block">
                            <div className="welcome-intro">
                                <h2 className="text-white text-center text-md-left">
                                    Dashboard
                                </h2>
                                <p className="text-white text-center text-md-left my-4">
                                    Fitur yang lengkap yang bisa disesuaikan dengan kebutuhan user. Pada Dashboard dapat mengetahui lokasi user, sehingga aplikasi akan menyesuaikan jadwal waktu ibadah user.
                                </p>
                                <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                    <a href="https://play.google.com/store/apps/details?id=dev.eoatech.yaumi" target="_blank" className="mr-3">
                                        <img src="./assets/img/website/icon/google_play.png" alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/id/app/amazing-riyadhoh/id1560939436?l=id" target="_blank">
                                        <img src="./assets/img/website/icon/app_store.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="welcome-thumb-wrapper pt-md-5 mt-5">
                                <span className="welcome-thumb-1 text-center">
                                    <img className="mx-auto d-block img-responsive" src="./assets/img/dashboard-mobile.png" alt="" />
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-block d-sm-none mb-4 mt-5">
                            <div className="welcome-intro">
                                <h2 className="text-white text-center text-md-left">
                                    Dashboard
                                </h2>
                                <p className="text-white text-center text-md-left my-4">
                                    Fitur yang lengkap yang bisa disesuaikan dengan kebutuhan user. Pada Dashboard dapat mengetahui lokasi user, sehingga aplikasi akan menyesuaikan jadwal waktu ibadah user.
                                </p>
                                <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                                    <a href="https://play.google.com/store/apps/details?id=dev.eoatech.yaumi" target="_blank" className="mr-3">
                                        <img src="./assets/img/website/icon/google_play.png" alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/id/app/amazing-riyadhoh/id1560939436?l=id" target="_blank">
                                        <img src="./assets/img/website/icon/app_store.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Aktivitas Hari ini */}
            <section className="section about-area ptb_50 mt-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section9.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Aktivitas Hari Ini</h2>
                                </div>
                                <p className="text-justify">
                                    Fitur untuk melihat ringkasan ibadah harian user pada hari itu, fitur jalan pintas untuk melihat aktivitas apa yang belum dikerjakan oleh user dengan cepat.   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Grafik */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block ">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Grafik</h2>
                                </div>
                                <p className="text-justify">
                                    Data dari setiap aktivitas yang user catat, akan disajikan dalam bentuk grafik, agar user dapat dengan jelas melihat aktivitas ibadah harian.  
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section10.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none ">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Grafik</h2>
                                </div>
                                <p className="text-justify">
                                    Data dari setiap aktivitas yang user catat, akan disajikan dalam bentuk grafik, agar user dapat dengan jelas melihat aktivitas ibadah harian.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Sholat */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Sholat</h2>
                                </div>
                                <p className="text-justify">
                                Catat selalu ketika melakukan Sholat.
                                Baik Sholat Wajib dan Sunnah. User dapat melihat grafik dari Sholat yang dikerjakan.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Al quran */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>AL-QUR’AN</h2>
                                </div>
                                <p className="text-justify">
                                Catat selalu ketika membaca dan menghafal.
                                User juga dapat melihat grafik sehingga membuat user lebih semangat dalam berbuat kebaikan.
                                </p> 
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section2.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>AL-QUR’AN</h2>
                                </div>
                                <p className="text-justify">
                                Catat selalu ketika membaca dan menghafal.
                                User juga dapat melihat grafik sehingga membuat user lebih semangat dalam berbuat kebaikan.
                                </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Puasa */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section6.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>PUASA</h2>
                                </div>
                                <p className="text-justify">
                                    Selalu catat ketika melakukan puasa, baik wajib maupun sunnah. User dapat melihat grafik dari puasa yang dikerjakan. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Ziswaf */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Ziswaf</h2>
                                </div>
                                <p className="text-justify">
                                    Zifwaf yaitu singkatan dari Zakat, Infaq, Wakaf, Sedekah. Jika user melakukan pencatatan, dari setiap aktivitas. Data user dapat kolaborasi dengan komunitas atau group. 
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section13.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Ziswaf</h2>
                                </div>
                                <p className="text-justify">
                                    Zifwaf yaitu singkatan dari Zakat, Infaq, Wakaf, Sedekah. Jika user melakukan pencatatan, dari setiap aktivitas. Data user dapat kolaborasi dengan komunitas atau group. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Kalimah Thayyibah */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section3.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>KALIMAH  THAYYIBAH</h2>
                                </div>
                                <p className="text-justify">
                                User dapat melakukan dzikir dibantu alat perhitungan.
                                Terdapat macam-macam dzikir, mulai dari Shalawat, Istigfar, Tasbih, Tahmid, Takbir, Tahlil, Hauqalah
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Jadwal Sholat */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Jadwal Sholat</h2>
                                </div>
                                <p className="text-justify">
                                    Di manapun lokasi user Amazing Riyadhoh, fitur jadwal sholat selalu menunjukkan jadwal yang akurat. Disertai dengan audio adzan uang bisa user aktifkan.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section8.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Jadwal Sholat</h2>
                                </div>
                                <p className="text-justify">
                                    Di manapun lokasi user Amazing Riyadhoh, fitur jadwal sholat selalu menunjukkan jadwal yang akurat. Disertai dengan audio adzan uang bisa user aktifkan.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             {/* Kiblat */}
             <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section7.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Kiblat</h2>
                                </div>
                                <p className="text-justify">
                                    Di manapun lokasi user Amazing Riyadhoh, fitur kiblat selalu menunjukkan arah ke Mekkah untuk membantu melakukan ibadah sholat.  
                                </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Doa */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>DO’A</h2>
                                </div>
                                <p className="text-justify">
                                    Amazing Riyadhoh selain menyediakan fitur pencatatan, menyediakan juga fitur doa yang bersumber dari Al-Qur’an, Hadist dan Nabi. Sangat membantu user dalam menghafal dan membaca doa sesuai keperluannya.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section4.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>DO’A</h2>
                                </div>
                                <p className="text-justify">
                                    Amazing Riyadhoh selain menyediakan fitur pencatatan, menyediakan juga fitur doa yang bersumber dari Al-Qur’an, Hadist dan Nabi. Sangat membantu user dalam menghafal dan membaca doa sesuai keperluannya.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Target Individu */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section12.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div clclassName="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Target Individu</h2>
                                </div>
                                <p className="text-justify">
                                    Fitur ini berfungsi untuk lebih menjaga dan meningkatkan ibadah user. Sehingga user diharapkan taat dalam beribadah dan berbuat kebaikan. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Group */}
            <section className="section about-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6 d-none d-sm-block">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Group</h2>
                                </div>
                                <p className="text-justify">
                                    Fitur group berfungsi untuk melakukan kolaborasi dalam kegiatan kebaikan. Nantinya di monitor dalam group tersebut. Fitur yang dapat di kolaborasi adalah Sholat, Ziswaf, Puasa, Al-Qur’an dan Dzikir.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="about-thumb text-center">
                                <img src="assets/img/mobile-section11.png" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-block d-sm-none">
                            <div className="content-inner pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                <div className="section-heading mb-3">
                                    <h2>Group</h2>
                                </div>
                                <p className="text-justify">
                                    Fitur group berfungsi untuk melakukan kolaborasi dalam kegiatan kebaikan. Nantinya di monitor dalam group tersebut. Fitur yang dapat di kolaborasi adalah Sholat, Ziswaf, Puasa, Al-Qur’an dan Dzikir.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default FeatureMobile