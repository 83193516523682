import React from "react";
import axios from "axios";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import DonationList from "../components/donation/DonationList";
import ShareDialog from "../components/donation/ShareDialog";

const Donate = () => {
    const [donationData, setDonationData] = React.useState(null);
    const [donationSum, setDonationSum] = React.useState(0);
    const [usersCount, setUsersCount] = React.useState(0);
    const [fastsCount, setFastsCount] = React.useState(0);
    const [ayatsReadCount, setAyatsReadCount] = React.useState(0);
    const [dhikrCount, setDhikrCount] = React.useState(0);
    const [accountCopiedLg, setAccountCopiedLg] = React.useState(false);
    const [accountCopiedSm, setAccountCopiedSm] = React.useState(false);
    const [pageCopied, setPageCopied] = React.useState(false);
    const accountNumber = '7220786597';
    const accountOwner = 'Amazing Riyadhoh'
    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const getDonationData = async () => {
        try {
            const uri = `${process.env.REACT_APP_BASE_URL}/api/donation?year=${year}&month=${month}`;
            const response = await axios.get(uri);
            setDonationData(response.data.data);
        } catch (err) {
          console.error(err.message);
        }
    }
    const getDonationSum = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/donation/total`);
            setDonationSum(response.data.data.total);
        } catch (err) {
            console.error(err);
        }
    }
    const getUsersCount = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stats/total-users`);
            setUsersCount(response.data.data.usersCount);
        } catch (err) {
            console.error(err);
        }
    }
    const getFastsCount = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stats/total-fasts`);
            setFastsCount(response.data.data.fastsCount);
        } catch (err) {
            console.error(err);
        }
    }
    const getAyatsReadCount = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stats/total-ayats-read`);
            setAyatsReadCount(response.data.data.ayatsCount);
            console.log('ayatsReadCount: ', ayatsReadCount);
        } catch (err) {
            console.error(err);
        }
    }
    const getDhikrCount = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stats/total-dhikr`);
            setDhikrCount(response.data.data.dhikrCount);
        } catch (err) {
            console.error(err);
        }
    }

    const handleCopyTextLg = async () => {
        try {
            await navigator.clipboard.writeText(accountNumber);
            setAccountCopiedLg(true);
            setAccountCopiedSm(false);
        } catch (err) {
            console.error(err)
        }
    }
    const handleCopyTextSm = async () => {
        try {
            await navigator.clipboard.writeText(accountNumber);
            setAccountCopiedSm(true);
            setAccountCopiedLg(false);
        } catch (err) {
            console.error(err)
        }
    }
    const handleClickShare = () => {
        setPageCopied(false)
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat(
            'id-ID',
        ).format(number)
    }

    React.useEffect(() => {
        getDonationData()
        getDonationSum()
        getUsersCount()
        getFastsCount()
        getAyatsReadCount()
        getDhikrCount()
    }, [])

    return (
        <React.Fragment>
            <Navbar />
                <section className="section ptb_100 mt-5">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <img
                                    id="copy"
                                    src="/assets/img/donasi.png" 
                                    alt="Ayo dukung pengembangan Amazing Riyadhoh"
                                />
                                <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-4">
                                {/* <div className="d-flex row align-items-center mt-4">  */}
                                    <div className="d-lg-none">
                                        <div className="d-flex align-items-center">
                                            <img src="/assets/img/bsi-logo.png" alt="Logo BSI"/>
                                            <div className="ml-2 mt-3">
                                                <h3>
                                                    {accountNumber}&nbsp;
                                                    <button className="btn btn-text" onClick={handleCopyTextSm} title="Salin nomor rekening">
                                                        <i class="fa-regular fa-copy text-primary"></i>
                                                    </button>
                                                    &nbsp;
                                                    {accountCopiedSm && <div class="badge rounded-pill alert-success" role="alert">
                                                        Tersalin!
                                                    </div>}
                                                </h3>
                                                <p>a.n {accountOwner}</p>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button class="btn btn-primary mt-2" data-toggle="modal" data-target="#share-dialog" onClick={handleClickShare}>
                                            <i class="fa-solid fa-share-nodes"></i>&nbsp;
                                            <span>Bagikan </span>
                                        </button>
                                    </div>
                                </div>

                                <p className="d-none d-lg-block text-justify mt-4">
                                    Amazing Riyadhoh lahir di lingkungan orang-orang yang senantiasa dalam kebaikan. 
                                    Berfokus pada ketauhidan kepada Allah SWT, dalam langkah menjadi teman untuk 
                                    menciptakan kebiasaan-kebiasaan baik dan meningkatkan ibadah secara bersama-sama 
                                    dengan fitur pencatatan amal ibadah harian dalam menjalani riyadhoh atau 
                                    pembentukan habit/kebiasaan dalam beribadah. <br /> <br />

                                    Aktivitas yang bisa dicatat antara lain sholat, puasa, ziswaf, dzikir, dan membaca 
                                    Al-Qur'an. Hingga saat ini Amazing Riyadhoh App sudah 
                                    mencatatkan {formatNumber(usersCount)} user, {formatNumber(fastsCount)} puasa, {formatNumber(ayatsReadCount)} ayat, dan {formatNumber(dhikrCount)} dzikir. <br /> <br />

                                    Kami membuka peluang anda untuk mendapatkan amal jariyah yang terus mengalir. <br />
                                </p>
                                
                            </div>
                            <div className="col-lg-4">
                                <div className="d-none d-lg-block">
                                    <h2>Jazakumullah Khairan Katsiran!</h2>
                                    <p className="text-justify my-4">
                                        Terima kasih kami ucapkan kepada pengguna Amazing Riyadhoh 
                                        yang telah memberikan dukungan untuk pengembangan Aplikasi 
                                        Amazing Riyadhoh. Berikut ini adalah transparasi pemasukkan 
                                        dan penggunaan dana untuk pengambangan aplikasi Amazing 
                                        Riyadhoh.
                                    </p>
                                </div>
                                <DonationList data={donationData} sum={donationSum} date={now} />
                                <div className="d-none d-lg-block">
                                    <p className="text-justify mt-4">
                                        Dapatkan peluang emas amal jariyah tak terputus yang terus mengalir 
                                        dengan menyisikan rizki untuk pengambangan aplikasi Amazing Riyadhoh.
                                    </p>
                                </div>
                                <div className="d-none d-lg-block">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img src="/assets/img/bsi-logo.png" alt="Logo BSI"/>
                                        </div>
                                        <div className="d-flex-column align-items-center my-4">
                                            <h3>
                                                {accountNumber}&nbsp;
                                                <button className="btn btn-text" onClick={handleCopyTextLg} title="Salin nomor rekening">
                                                    <i class="fa-regular fa-copy text-primary"></i>
                                                </button>
                                            </h3>
                                            <p>a.n {accountOwner}</p>
                                        </div>
                                    </div>
                                    {accountCopiedLg && <div class="alert alert-success text-center" role="alert">
                                        Tersalin!
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <ShareDialog copied={pageCopied} setCopied={setPageCopied} />  
            <Footer />
        </React.Fragment>
    )
}

export default Donate;